.section {
    width: 100%;
    position: relative;
}

/* lenis */
html.lenis, html.lenis body {
    height: auto;
  }
  
  .lenis.lenis-smooth {
    scroll-behavior: auto !important;
  }
  
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-stopped {
    overflow: hidden;
  }
  
  .lenis.lenis-smooth iframe {
    pointer-events: none;
  }