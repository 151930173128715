
.aboutSection {
    height: 100vh;
    width: 100%;
    z-index: 100;
    position: relative;
    
    display: flex;
    background-image: linear-gradient(to bottom, #000000, #141A33, #1A294D, #2A3F6E, #3A5490, #4A6AB2, #5A80D4);

    
  }
  
  .gridContainer {
   
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    position: absolute;
    border-radius: 50px;
    top: 20%;
    right: 10%;
    width: 30%;
    aspect-ratio: 1;
    
    overflow: hidden; /* Hide overflow to ensure rounded corners are visible */
}




  .gridItem {
   
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    transform-origin: center;
    scale: 0.5;
    opacity: 0;
    transform: translateX(0px) translateY(0px);
  }
  .aboveContainer{
    border-radius: 50px;
    top: 20%;
    right: 10%;
    width: 30%;
    aspect-ratio: 1;
    opacity: 0;
   
    position: absolute;
    
  }
  .svgContainer {
    width: 100%;  /* Full width of the container */
    height: 100%; /* Full height of the container, or specify a height */
    position: relative; /* Ensure the container has a defined position */
}


.svgContainer  svg {
    width: 100%;
    height: 100%;
    display: block; /* Remove extra space below the SVG */
    stroke-width: 60px;
}
.about {
    padding: 20px;
    
    border-radius: 10px; /* Optional: rounded corners */
    max-width: 300px;
   
    position: absolute;
    left: 10%;
    opacity: 0;
    top: 20%;
    text-align: center; /* Center the text */
}

.about h1 {
    color: #fff; /* White color for the title */
    font-weight: 700; /* Bold font weight */
    margin-bottom: 15px; /* Spacing below the title */
}


 p {
    color: #fff; /* White color for the paragraph */
    font-weight: 500; /* Normal font weight */
    line-height: 1.6; /* Line height for readability */
}


@media only screen and (max-width: 768px) {
   
  .aboveContainer,.gridContainer{
      width: 60%;
      margin: auto;
      left:0;
      right: 0;
      top: 10%;
     
  }
  .about{
    
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, 0%);
  }
}

@media only screen and (max-width: 515px) {
   
    .aboveContainer,.gridContainer{
        width: 60%;
        margin: auto;
        left:0;
        right: 0;
        top: 10%;
       
    }
    .about{
        position: absolute;
        top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
    }
}


