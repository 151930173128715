.ourWorkSection {

    display:flex;	
    justify-content:center;
  
  z-index: 10;
  }
  
  .left {
    width:50%;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
      
  }
  
  .detailsWrapper {
   color:black;
      margin:auto;
      width:100%;
  }
  .detailsWrapper p,.title{
    color:black;
  }
  .details {
      height:100vh;
      
      display:flex;
      flex-direction:column;
      justify-content:center;
  }
  
  
  .right {
    width: 50%;
  }
  
  .photos {
      width:400px;
      height:400px;
      background:maroon;
      position:relative;
      border-radius: 50px;
  }
  
  .photo {
      position:absolute;
      width:100%;
      height:100%;
      border-radius: 10px;
  }
  .main{
    position: relative;
    width: 100%;
  
  
  }
  .bg{
    position: absolute;
    top: 0;
    left: 0;
   
    height: 100vh;
    width:100%;
  }
  .one{
  
    background-color: #5A80D4;

  }
  .two{
  
    background-color: #D9ABAB;
    
  }
  .three{
  
    background-color: #7FA1C3;
    
  }
 
  .color{
        position:absolute;
        
        width:100%;
        height:100%;
      
   

  }
.fusulApp {
    background-image: url('../../assets//images/12.png'); /* Replace with your image URL */
 background-position: center;
 background-size: contain;  /* Ensure the image fits within the div */
 background-position: cover; /* Center the image within the div */
 background-repeat: no-repeat;
 background-color: #041938;

}
  
  .qusasat {
    background-image: url('../../assets//images/10.png'); /* Replace with your image URL */
    background-position: center;
    background-size: contain;  /* Ensure the image fits within the div */
    background-position: cover; /* Center the image within the div */
    background-repeat: no-repeat;
    background-color: #942c2c

    ;

}
  
  .watheeq {
    background-image: url('../../assets//images/11.png'); /* Replace with your image URL */
    background-position: center;
    background-size: contain;  /* Ensure the image fits within the div */
    background-position: cover; /* Center the image within the div */
    background-repeat: no-repeat;
    background-color: #041938;

  

}
.title{
    
    position: sticky;
    left: 50%;
    top: 0;
    height: 10%;
    color: black;
}
.headline {
	color: black;
	
	text-align: center;
    font-family: 'Cairo', sans-serif;
    font-weight: bold;
    font-size: 30px;

	width:100%;
}
.image{
    margin:20px auto;
    width: 150px;
    height: 50px;
}
.logo{
    width: 100px;
    height:100px;
    margin: 20px auto;
    border-radius: 50%;
}
@media only screen and (max-width: 768px) {
    .photos{
        width: 300px;
        height: 300px;
        transform:translateX(-30%)
    }
   
    .image{
        height: 30px;
        width : 90px;
    }
    .logo{
        width: 50px;
        height:50px;
      
    }
}

@media only screen and (max-width: 515px) {
  .photos{
      width: 200px;
      height: 200px;
      transform:translateX(-30%)
  }
  .detailsWrapper p{
 
      display: none;
  }
  .image{
      height: 30px;
      width : 90px;
  }
  .logo{
      width: 50px;
      height:50px;
    
  }
}

