
.ourservice {
    display: flex;
    justify-content: space-between;
 
    height: 100vh;
    background-color: #7FA1C3;
   
}

.leftSection {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1px;
    row-gap: 20px;
    height: 40%;
    z-index:2;
    width: 30%;
    
}
.serviceTitle{
    margin:0 auto;
    color: white;
}
.gridItemService {
    background-color: #e0e0e0;
    border-radius: 8px; 
    opacity: 0;
    z-index:2;
    width: 100px; /* Ensure all items have consistent width */
}

.itemservice1 {
    background-image: url('https://cdn.pixabay.com/photo/2016/10/09/08/32/digital-marketing-1725340_1280.jpg');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
    background-color: #e1e1e0;
    grid-row-start: 1;
    grid-row-end: 3; /* Spans 2 rows */
}

.itemservice2 {
    background-image: url('https://cdn.pixabay.com/photo/2015/09/05/23/11/macbook-926121_1280.jpg');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
    background-color: #e1e1e0;   
    width: 180%;
    grid-row-start: 1;
    grid-row-end: 2; /* Spans 1 row */
}

.itemservice3 {
    background-image: url('https://cdn.pixabay.com/photo/2016/11/21/18/20/bright-1847006_1280.jpg');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
    background-color: #e1e1e0;
    grid-row-start: 2;
    grid-row-end: 3; /* Spans 3 rows */
}

.itemservice4 {
    background-image: url('https://cdn.pixabay.com/photo/2017/07/31/11/09/computer-2557299_1280.jpg');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
    background-color: #e1e1e0;
    width: 180%;
    grid-row-start: 3;
    grid-row-end: 4; /* Spans 1 row */
}

.itemservice5 {
    background-image: url('https://cdn.pixabay.com/photo/2017/08/06/09/27/laptop-2590646_1280.jpg');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
    background-color: #e1e1e0;
    grid-row-start: 2;
    grid-row-end: 4; /* Spans 2 rows */
}


.rightSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    z-index:2;
   
    align-items: center;
    justify-content: space-between;
}
.serviceContainer{
    display: flex;
    
    width: 100%;
    height: 60%;
    justify-content: center;
    align-items: start;
    gap: 20px;
}


.serviceBox {
    z-index:2;
    background-color: #ff9800;
    border-radius: 12px;
  
    text-align: center;
    color: white;
    font-weight: bold;
    width: 200px;
    position: relative;
    height: 70px;
}

.videoContainer{
   background-color: red;
    width: 100%;
   
    height: 100vh;
    display: flex;
    align-items: center;
    position: absolute;
   
}
.websites {
    z-index:2;
   
    background-image: url('https://img.freepik.com/free-vector/web-hosting-isometric-composition-with-icons-website-windows-buttons-desktop-computer-with-people-vector-illustration_1284-80933.jpg?t=st=1725810987~exp=1725814587~hmac=a30c4840ed0839bb4df96c43c849809995edab819880a70d3ba6560f9206345e&w=826');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
     margin-top: 2%;
    background-color: #e1e1e0;
}
.websites p{
    color: #000;
    font-size: medium;
    font-weight: 300;
}
.websites h3{
  
        z-index:2;
        color: #000;
        font-size: large;
        font-weight: bold;
   
}
.apps p{
    z-index:2;
    color: #000;
    font-size: medium;
    font-weight: 300;
}
.apps h3{
    z-index:2;
    color: #fff;
    font-size: large;
    text-align: center;
    font-weight: bold;
}
.apps {
    z-index:2;
    background-image: url('https://img.freepik.com/free-vector/antigravity-mobile-phone-with-elements_23-2148281819.jpg?t=st=1725811074~exp=1725814674~hmac=61573b7b11c5103eb5451064eb3cd414f8973fac838dc82cf3835fa3318444fa&w=826');
    background-position: center;
    background-size: cover;  /* Ensure the image fits within the div */
    background-position: center; /* Center the image within the div */
    background-repeat: no-repeat;
     z-index:2;
     margin-top: 2%;
    background-color: #e1e1e0;
}
.info {
    display: none;
    /* Add any styles for the info section */
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 20px;
    position: absolute;
    top: 120%; /* Adjust as needed */
    left: 0;
    z-index: 0;
  }
  .info p {
    direction: rtl;
    font-weight: bold;
  }
  .serviceBox:hover .info {
    display: block;
  }
.video1,.video2{
    position: absolute;
    height: 100%;
    width: 100%;
}
.video1{
    z-index:1;
   

}

video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Adjusts the video to cover the entire container */
  }



  @media only screen and (max-width: 768px) {
 .ourservice{
    flex-direction: column;
 }


 .gridItemService {
    background-color: #e0e0e0;
    border-radius: 8px; 
    opacity: 0;
    z-index:2;
    width: 110px; /* Ensure all items have consistent width */
}

.leftSection {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    height: 40%;
    z-index:2;
    width: 90%;
  
    
}
.itemservice4,.itemservice2{
    width: 180%;
}

}