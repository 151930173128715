
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    background: rgba(255, 255, 255, 0.2);
   
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
   position:absolute;
    width: 100%;
  
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s;
  }
  
  .navLogo {
    color: #fff;
    background-image: url('../../assets/icons/logo.svg');
    background-repeat: no-repeat; /* Repeat horizontally */
    background-size: cover; /* Adjust the size of the image */
    width: 50px; /* Width of the container */
    height: 50px;
    margin: 0  20px;
    border-radius: 50%;
  }
  .navLogo{
    height: 30px;
    width:30px;
  }
  .navMenu {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: auto;
    flex-direction: row-reverse;
    gap: 20px;
 
    color: #fff;
  }
  .navMenu li{
    padding: 5px 20px;
    margin: 0 20px;
  
    border-radius: 20px;
  }


 
  .navMenu li:hover {
    background-color: white;
    color: white;
    
    border-radius: 20%;
  }
  .navMenu li a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .navMenu li a:hover {
    color: black;
  }
  .active a{
 
    color: white ;
    
   }
   .active{
    padding: 5px 20px;
    margin: 0 20px;
    background-color: white;
   color: black  !important;
    border-radius: 20%;
  }
  .active a{
    
   color: black  !important;
   
  }
  .nav-toggle {
    display: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    .navbar{
      justify-content: center;
    }
  
     .navMenu{
    
      display: none;
     }}