/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif;
  color: #333;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #000000, #000000, #141415, #202123, #2b2f30, #373d3c);}
