/* src/components/home/ParallaxSection.module.css */
.firstSection {
    height: 100vh;
  }
  
  .parallaxContainer {
    position: sticky;
    top: 0;
    background-color:red;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: hidden;
  }
  .parallaxContainer::after{
    content: '';
    background-color:#000000;
    height: 10%;
    width: 100%;
    position: absolute;
    bottom:-5%;
    filter: blur(5px);
    z-index: 500;
  }
  
  .parallax {
    position: absolute;
  }
  
  .mountain {
   
 
    width: 100%;
   height: 100%;
    bottom: 0;
    z-index: 3;
    background-image: url('../../assets/images/mountain.svg');
    background-repeat: repeat-x;
    background-size: contain;
  }
  
  .trees {
    background-image: url('../../assets/images/trees.svg');
    background-repeat: repeat-x;
    background-size: contain;
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 0;
    z-index: 15;
  }
  
  .videoBackground {
    position: absolute;
 
    background-color: green;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
   
    
    filter: grayscale(100%);
  }
  .moon {
    width: 50%;
    top: 10%;
    right: 40%;
    z-index: 1;
  }
  
  .sky {
    width: 120%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  
  
  .text {
    color: white;
    font-size: 8rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  /* Tablet screen (768px and below) */

  @media only screen and (max-width: 768px) {
    .mountain {
   background-position: bottom;
   background-size:  200%;
      /* transform: translateX(-10%); */
    }
    
    .trees {
      background-position: bottom;
      background-size: contain;
   
    }
    
    .videoBackground {
   
   
      object-fit: cover;
     
    
    }
}

