.App {
  text-align: center;
  background-color: black;
width: 100%;
overflow-x: hidden;
}

.section {
  width: 100%;
  position: relative;
}