
  .container{
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: bisque;
    
display: flex;
align-items: center;
justify-content: center;
}
.ContactUs {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
 
  text-align: center;
  cursor: pointer;
}

.contactTitle {
  position: absolute;
  font-size: 1.5em;
  font-weight: bold;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  height: 150px;
  width: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactTitle p{
color: black;
font-weight: bold;

}

.socialIcons {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
background-color: transparent;
  transition: transform 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: spin 20s linear infinite;
} 

.hovered {
  opacity: 1;
 
}

.socialIcon {
  position: absolute;
  width: 60px;
  height: 60px;
  
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  transition: all 1s ease;
  transform: translate(-50%, -50%) scale(0.1);
}

.hovered .socialIcon:nth-child(1) { transform: rotate(0deg) translate(120px) rotate(0deg); }
.hovered .socialIcon:nth-child(2) { transform: rotate(60deg) translate(120px) rotate(-60deg); }
.hovered .socialIcon:nth-child(3) { transform: rotate(120deg) translate(120px) rotate(-120deg); }
.hovered .socialIcon:nth-child(4) { transform: rotate(180deg) translate(120px) rotate(-180deg); }
.hovered .socialIcon:nth-child(5) { transform: rotate(240deg) translate(120px) rotate(-240deg); }
.hovered .socialIcon:nth-child(6) { transform: rotate(300deg) translate(120px) rotate(-300deg); }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.socialIcon:hover {
  background-color: #ff6347;
}
@media only screen and (max-width: 768px) {
  .socialIcon:nth-child(1) { transform: rotate(0deg) translate(120px) rotate(0deg); }
  .socialIcon:nth-child(2) { transform: rotate(60deg) translate(120px) rotate(-60deg); }
  .socialIcon:nth-child(3) { transform: rotate(120deg) translate(120px) rotate(-120deg); }
  .socialIcon:nth-child(4) { transform: rotate(180deg) translate(120px) rotate(-180deg); }
  .socialIcon:nth-child(5) { transform: rotate(240deg) translate(120px) rotate(-240deg); }
  .socialIcon:nth-child(6) { transform: rotate(300deg) translate(120px) rotate(-300deg); }
  .socialIcons {
   
    opacity: 1;
  } 
}